<template>
  <BaseLayout>
    <template #header>
      <h2>Daftar Pengiriman Labu</h2>
      <b-button variant="primary" to="/dashboards/blood-shipments/add">
        Tambah Data Pengiriman
      </b-button>
      <div class="mt-3 d-flex justify-content-between align-items-end">
        <p class="hp-p1-body mb-0 text-black">Semua: ( {{ table.total }} )</p>
      </div>
    </template>
    <template #content>
      <b-table
        :items="table.items"
        :fields="table.fields"
        :busy.sync="isBusy"
        responsive
        show-empty
      >
        <template #cell(action)="row">
          <b-button-group>
            <router-link
              :to="{
                name: 'detail_blood_shipments',
                params: {
                  date: row.item.date,
                  mu_name: row.item.mu_name,
                  division: row.item.division,
                },
              }"
            >
              <b-button variant="info">
                <i class="ri-stethoscope-fill mr-1" />
                Detail</b-button
              >
            </router-link>
          </b-button-group>
        </template>
        <template #cell(status)="row">
          <b-badge :variant="getStatusVariant(row.item.status)">
            {{
              row.item.status === "Sudah diterima"
                ? "Sudah diterima"
                : "Belum diterima"
            }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
        @input="changePage"
      />
    </template>
  </BaseLayout>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BSpinner,
  BTable,
} from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../../api/blood_shipment/manageBloodShipmentAPI";
import tableDataMixins from "../../../../../mixins/tableDataMixins";

export default {
  name: "PengirimanDarah",
  components: {
    BPagination,
    BSpinner,
    BBadge,
    BButton,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      table: {
        fields: [
          {
            key: "date",
            label: "Tanggal",
            sortable: true,
          },
          {
            key: "mu_name",
            label: "Mobil Unit",
            sortable: true,
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
          {
            key: "division",
            label: "Divisi",
          },
          {
            key: "action",
            label: "Aksi",
          },
        ],
      },
      isBusy: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.table.loading = this.isBusy;
        const params = {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
        };

        const { data: response } = await manageBloodShipmentAPI.getAllShipments(
          params
        );

        this.table.items = response.data.data;
        this.table.total = response.data.total;
      } catch (e) {
        console.error(e);
      }
    },
    getStatusVariant(status) {
      switch (status.toLowerCase()) {
        case "Sudah diterima":
          return "success";
        default:
          return "warning";
      }
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
  },
};
</script>
