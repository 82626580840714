var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',[_vm._v("Daftar Pengiriman Labu")]),_c('b-button',{attrs:{"variant":"primary","to":"/dashboards/blood-shipments/add"}},[_vm._v(" Tambah Data Pengiriman ")]),_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-end"},[_c('p',{staticClass:"hp-p1-body mb-0 text-black"},[_vm._v("Semua: ( "+_vm._s(_vm.table.total)+" )")])])]},proxy:true},{key:"content",fn:function(){return [_c('b-table',{attrs:{"items":_vm.table.items,"fields":_vm.table.fields,"busy":_vm.isBusy,"responsive":"","show-empty":""},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"cell(action)",fn:function(row){return [_c('b-button-group',[_c('router-link',{attrs:{"to":{
              name: 'detail_blood_shipments',
              params: {
                date: row.item.date,
                mu_name: row.item.mu_name,
                division: row.item.division,
              },
            }}},[_c('b-button',{attrs:{"variant":"info"}},[_c('i',{staticClass:"ri-stethoscope-fill mr-1"}),_vm._v(" Detail")])],1)],1)]}},{key:"cell(status)",fn:function(row){return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(row.item.status)}},[_vm._v(" "+_vm._s(row.item.status === "Sudah diterima" ? "Sudah diterima" : "Belum diterima")+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"hp-p1-body text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-8"}),_c('strong',[_vm._v("Memuat...")])],1)]},proxy:true}])}),_c('b-pagination',{staticClass:"mb-3",attrs:{"align":"right","per-page":_vm.table.perPage,"total-rows":_vm.table.total},on:{"input":_vm.changePage},model:{value:(_vm.table.currentPage),callback:function ($$v) {_vm.$set(_vm.table, "currentPage", $$v)},expression:"table.currentPage"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }